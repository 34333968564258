import {
  DashboardControllerConfig,
  WixCodeAPI,
} from '@wix/blocks-widget-services-types';
import { createDashboardController } from './createDashboardController';
import { getRemoteModuleParamsBase } from './getRemoteModuleParams';
import { RemoteModuleArgsBase } from './services/remoteModuleArgs';
import { createSiteClientParams } from './sdkProvider/siteSdkParamsProvider';

export type DashboardModuleArgs = RemoteModuleArgsBase;

export type DashboardModule = (args: DashboardModuleArgs) => Promise<{}>;
const isRunningInPreview = (wixCodeApi: WixCodeAPI) =>
  wixCodeApi.window.viewMode === 'Preview';

export async function createRemoteDashboardController(
  controllerConfig: DashboardControllerConfig,
  dashboardModule: DashboardModule | undefined,
) {
  return createDashboardController(
    controllerConfig,
    async (dashboardControllerConfig: DashboardControllerConfig) => {
      controllerConfig.appParams.instance =
        controllerConfig.wixCodeApi.location.query?.instance ||
        controllerConfig.appParams.instance;

      if (
        controllerConfig.essentials.experiments.enabled(
          'specs.blocks-preview-client.validateAuthorizationCodeOnPanelAndDashboardLoading',
        ) &&
        !controllerConfig.wixCodeApi.location?.query?.authorizationCode &&
        !isRunningInPreview(controllerConfig.wixCodeApi)
      ) {
        throw new Error('No authorization code on dashboard loading');
      }

      const remoteModuleParams = await getRemoteModuleParamsBase(
        dashboardControllerConfig,
        () =>
          createSiteClientParams(
            dashboardControllerConfig.wixCodeApi,
            dashboardControllerConfig.appParams.appDefinitionId,
            dashboardControllerConfig.appParams.instance,
          ),
      );

      if (dashboardModule) {
        await dashboardModule(remoteModuleParams);
      }

      /* istanbul ignore next */
      return {
        pageReady: () => {},
        exports: () => ({}),
      };
    },
  );
}
