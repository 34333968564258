import {
  ControllerWithExportsFunction,
  PanelControllerConfig,
} from '@wix/blocks-widget-services-types';
import { PanelCreationError } from './errors/panelCreationError';

export type CreatePanelFn = (
  controllerConfig: PanelControllerConfig,
) => Promise<ControllerWithExportsFunction<Record<string, any>>>;

function setPanelPetriGlobalConfig(controllerConfig: PanelControllerConfig) {
  const { location } = controllerConfig.wixCodeApi;
  const { siteOwnerId, userId } = location.query;

  if (!siteOwnerId || !userId) {
    return;
  }

  self.__WIXEXP_CONDUCTION_METHOD_ = 'owner-account';
  self.__WIXEXP_LOGGED_IN_USER_ID_ = userId;
  self.__WIXEXP_OWNER_ACCOUNT_ID_ = siteOwnerId;
}

export async function createPanelController(
  controllerConfig: PanelControllerConfig,
  createPanelFn: CreatePanelFn,
): Promise<ControllerWithExportsFunction<Record<string, any>>> {
  try {
    const panelController = await createPanelFn(controllerConfig);

    setPanelPetriGlobalConfig(controllerConfig);

    /* istanbul ignore next */
    return {
      pageReady: (scoped$w) => {
        if (panelController?.pageReady) {
          return panelController.pageReady(scoped$w);
        }
      },
      exports: () => ({}),
    };
  } catch (e) {
    throw new PanelCreationError(e);
  }
}
