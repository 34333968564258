import {
  PanelControllerConfig,
  WixCodeAPI,
} from '@wix/blocks-widget-services-types';
import { createPanelController } from './createPanelController';
import { getRemoteModuleParamsBase } from './getRemoteModuleParams';
import { RemoteModuleArgsBase } from './services/remoteModuleArgs';
import { CreateClientParams } from './services';

export type PanelModuleArgs = RemoteModuleArgsBase;

export type PanelModule = (args: PanelModuleArgs) => Promise<{}>;
const isRunningInPreview = (wixCodeApi: WixCodeAPI) =>
  wixCodeApi.window.viewMode === 'Preview';

export async function createRemotePanelController(
  controllerConfig: PanelControllerConfig,
  panelModule: PanelModule,
  createSdkParams?: () => CreateClientParams,
) {
  return createPanelController(
    controllerConfig,
    async (panelControllerConfig: PanelControllerConfig) => {
      panelControllerConfig.appParams.instance =
        (await panelControllerConfig.wixCodeApi.editor?.getAppInstance()) ||
        panelControllerConfig.appParams.instance;

      if (
        controllerConfig.essentials.experiments.enabled(
          'specs.blocks-preview-client.validateAuthorizationCodeOnPanelAndDashboardLoading',
        ) &&
        !controllerConfig?.wixCodeApi?.location?.query?.authorizationCode &&
        !isRunningInPreview(controllerConfig.wixCodeApi)
      ) {
        throw new Error('No authorization code on panel loading');
      }

      const remoteModuleParams = await getRemoteModuleParamsBase(
        panelControllerConfig,
        createSdkParams,
      );

      await panelModule(remoteModuleParams);

      /* istanbul ignore next */
      return {
        pageReady: () => {},
        exports: () => ({}),
      };
    },
  );
}
