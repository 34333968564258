import {
  PreviewMetaDataProvider,
  PreviewWidgetModuleProvider,
  RemoteDashboardLoader,
  RemotePanelLoader,
  RemoteWidgetsLoader,
  createLowCodeController,
} from '@wix/blocks-widget-services';
import {
  BlocksPreviewAppData,
  BlocksPreviewAppParams,
  isDashboardControllerConfig,
  isPanelControllerConfig,
  isLowCodeControllerConfig,
  PlatformAPIs,
  PlatformServicesAPI,
  PreviewControllerConfig,
  WixCodeAPI,
} from '@wix/blocks-widget-services-types';
import { AppOverride, PlatformViewerApp } from '../types/types';
import { getAppOverride, getRootControllerConfig } from './appDataUtils';
import {
  adjustConfigsForOverriddenScript,
  wrapControllerConfigsForPreviewApp,
} from './controllerConfigsWrapper';
import loadScript from './scriptLoader';
import { CommonLogger, experiments } from '@wix/blocks-widget-services/common';
import constants from './constants';
import { IAppData } from '@wix/native-components-infra/dist/src/types/types';
import { editor } from '@wix/editor';

const emptyController = {
  pageReady() {},
  exports() {
    return {};
  },
};

const getRemoteWidgetsLoader = (appParams: IAppData<BlocksPreviewAppData>) => {
  const previewMetaDataProvider = new PreviewMetaDataProvider(appParams);
  const previewWidgetModuleProvider = new PreviewWidgetModuleProvider(
    appParams,
  );

  return new RemoteWidgetsLoader(
    appParams,
    previewMetaDataProvider,
    previewWidgetModuleProvider,
  );
};

const getRemotePanelLoader = (appParams: IAppData<BlocksPreviewAppData>) => {
  return new RemotePanelLoader(appParams);
};

const getRemoteDashboardLoader = (
  appParams: IAppData<BlocksPreviewAppData>,
) => {
  return new RemoteDashboardLoader(appParams);
};

const doesWixCodeInstanceHavePermissionsScope = (
  controllerConfig: PreviewControllerConfig,
) => {
  const wixCodeSignedInstance = (
    controllerConfig.platformAPIs as any
  ).essentials.env.platformEnv.getAppToken(constants.WIX_CODE_APP_DEF_ID);

  const decodedInstance = JSON.parse(atob(wixCodeSignedInstance.split('.')[2]));
  // A workaround - dev-center send the appId & version in the permissionScope
  return !!decodedInstance.permissionScope;
};

const doesWixBlocksInstanceHavePermissionsScope = (
  controllerConfig: PreviewControllerConfig,
) => {
  const wixBlocksSignedInstance = (
    controllerConfig.platformAPIs as any
  ).essentials.env.platformEnv.getAppToken(constants.APP_DEF_ID);

  const decodedInstance = JSON.parse(
    atob(wixBlocksSignedInstance.split('.')[1]),
  );

  return !!decodedInstance.ps;
};

const isEnforcedAppPermission = (controllerConfig: PreviewControllerConfig) => {
  try {
    const shouldUseWixBlocksInstance = experiments.isOpen(
      'specs.blocks-preview-client.useBlocksInstanceForPermissionEnforcementCheck',
    );

    return shouldUseWixBlocksInstance
      ? doesWixBlocksInstanceHavePermissionsScope(controllerConfig)
      : doesWixCodeInstanceHavePermissionsScope(controllerConfig);
  } catch {
    console.warn('Failed to decode signedInstance');
    return false;
  }
};

const setVeloAuthorization = (controllerConfig: PreviewControllerConfig) => {
  if (isEnforcedAppPermission(controllerConfig)) {
    // TODO: once MS-5116 is done, we should pass here the real app instance instead of the preview-app's instance
    controllerConfig.wixCodeApi.elementorySupport?.setHeader(
      'Authorization',
      controllerConfig.appParams.instance,
    );
  }
};

const createPreviewControllers = (
  controllersConfigs: PreviewControllerConfig[],
  logger: CommonLogger,
) => {
  if (controllersConfigs.length === 0) {
    return [];
  }

  const { appParams } = controllersConfigs[0];

  const remoteWidgetsLoader = getRemoteWidgetsLoader(appParams);
  const remotePanelsLoader = getRemotePanelLoader(appParams);
  const remoteDashboardLoader = getRemoteDashboardLoader(appParams);

  async function loadController(controllerConfig: PreviewControllerConfig) {
    if (isDashboardControllerConfig(controllerConfig)) {
      setVeloAuthorization(controllerConfig);
      return remoteDashboardLoader.loadDashboard(controllerConfig);
    }

    if (isPanelControllerConfig(controllerConfig)) {
      logger.interactionStarted(constants.INTERACTIONS.LOAD_PANEL_CONTROLLER);
      setVeloAuthorization(controllerConfig);

      const getSdkParams = () => {
        return {
          host: editor.host(),
          auth: editor.auth(),
        };
      };
      const panelController = await remotePanelsLoader.loadPanel(
        controllerConfig,
        getSdkParams,
      );
      logger.interactionEnded(constants.INTERACTIONS.LOAD_PANEL_CONTROLLER);
      return panelController;
    }

    if (isLowCodeControllerConfig(controllerConfig)) {
      return createLowCodeController(controllerConfig);
    }

    return remoteWidgetsLoader.loadWidget(controllerConfig);
  }

  return controllersConfigs.map(async (controllerConfig) => {
    try {
      logger.interactionStarted(constants.INTERACTIONS.LOAD_WIDGET);

      const controller = await loadController(controllerConfig);

      logger.interactionEnded(constants.INTERACTIONS.LOAD_WIDGET);

      return controller;
    } catch (e) {
      logger.reportError('Failed loading widget', e);
      return emptyController;
    }
  });
};

function getCodePageIdByConfig(controllerConfig: PreviewControllerConfig) {
  if (isDashboardControllerConfig(controllerConfig)) {
    return controllerConfig.config.dashboardId;
  }
  if (isLowCodeControllerConfig(controllerConfig)) {
    return;
  }
  return controllerConfig.config.type;
}

function printDebugMessage(controllersConfigs: PreviewControllerConfig[]) {
  const rootControllerConfig = getRootControllerConfig(controllersConfigs);

  if (!rootControllerConfig) {
    return;
  }

  const codePageId = getCodePageIdByConfig(rootControllerConfig);

  if (!codePageId) {
    return;
  }
  console.info(`To debug this code, open ${codePageId}.js in Developer Tools.`);
}

function createApp(): PlatformViewerApp {
  const logger = new CommonLogger();
  let appOverride: AppOverride | undefined;

  const initLogger = (platformServicesAPI: PlatformServicesAPI) => {
    logger.init(platformServicesAPI, {
      appDefId: constants.APP_DEF_ID,
      errorMonitorOptions: {
        dsn: constants.SENTRY.DSN,
        environment: process.env.NODE_ENV,
        version: process.env.ARTIFACT_VERSION,
        appName: process.env.ARTIFACT_ID,
        tags: {
          ...CommonLogger.getCommonTags(platformServicesAPI),
        },
      },
      fedOps: {
        interactionPrefix: constants.INTERACTIONS.PREFIX,
      },
    });
  };

  const initAppForPage = async (
    appParams: BlocksPreviewAppParams,
    platformAPIs: PlatformAPIs,
    wixCodeApi: WixCodeAPI,
    platformServicesAPI: PlatformServicesAPI,
  ) => {
    initLogger(platformServicesAPI);

    try {
      logger.interactionStarted(constants.INTERACTIONS.INIT_APP_FOR_PAGE);
      await experiments.init({
        scope: constants.EXPERIMENTS_SCOPE,
        wixCodeApi,
        platformServicesAPI,
        logger,
        preloadedExperiments: platformServicesAPI.essentials.experiments.all(),
      });

      appOverride = getAppOverride(wixCodeApi, appParams);
      if (appOverride) {
        console.info(
          `Loading overridden script for appId[${appOverride.appDefId}] from "${appOverride.viewerScriptUrl}"`,
        );

        appOverride.module = loadScript(appOverride.viewerScriptUrl);

        return appOverride.module?.initAppForPage(
          appParams,
          platformAPIs,
          wixCodeApi,
          platformServicesAPI,
        );
      }
      logger.interactionEnded(constants.INTERACTIONS.INIT_APP_FOR_PAGE);
    } catch (e) {
      logger.reportError('Failed running initAppForPage', e);
    }
  };

  const createControllers = (
    controllersConfigs: PreviewControllerConfig[],
    ...rest: unknown[]
  ) => {
    if (controllersConfigs[0]?.wixCodeApi.window.rendering?.env === 'backend') {
      return [];
    }
    const wrappedControllerConfigs =
      wrapControllerConfigsForPreviewApp(controllersConfigs);

    if (appOverride && appOverride.module) {
      const adjustedConfigs = adjustConfigsForOverriddenScript(
        wrappedControllerConfigs,
        appOverride.appDefId,
      );

      return appOverride.module.createControllers(adjustedConfigs, ...rest);
    }

    printDebugMessage(wrappedControllerConfigs);

    return createPreviewControllers(wrappedControllerConfigs, logger);
  };

  return {
    initAppForPage,
    createControllers: logger.wrapWithErrorReporter(
      createControllers,
      'Failed running create controllers',
    ),
  };
}

export const { initAppForPage, createControllers } = createApp();
